import React, {useState} from 'react';
import classNames from "classnames";
import { useResource } from 'retil';
import { Link } from 'react-navi';
import {Entries, Photo} from '@2021-contest-web/components/src/components';
import {Entry} from "@2021-contest-web/components/src/types";
import { StepBox } from '@2021-contest-web/components/src/components/StepBox';
import { Hero, Footer } from '../../components';
import {entryList} from "../../resources";
import {EntryCard} from "../../components/card";
import { Twitter } from '../../components/twitter';
import TweetEmbed from 'react-tweet-embed';

export function TopApp() {
  const [entries] = useResource(entryList);
  const [openContent1, setOpenContent1] = useState(false);
  const [openContent2, setOpenContent2] = useState(false);
  const [openContent3, setOpenContent3] = useState(false);
  const [openContent4, setOpenContent4] = useState(false);
  return (
    <main className="TopApp">
      <Hero />
      <section id="about" className="section as-about-section">
        <div className="container" style={{ maxWidth: 550 }}>
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">About</h2>
          </div>
          <p
            className="notification is-padding-top-1-5 is-padding-bottom-1-5 is-padding-left-1-5 is-padding-right-1-5 is-margin-bottom-3 is-size-7-mobile"
            style={{ margin: '0 auto' }}
          >
            水コンはお茶の水女子大学の徽音祭で開催される、選出されたファイナリストが様々な角度から自分を魅せるコンテストです。
            <br />
            <br />
            お披露目から徽音祭までの4ヶ月間の活動で、ファイナリストには
            <strong className="has-text-marker">自分の更なる魅力</strong>
            を見つけ、それを発信してもらいます。
            <br />
            <br />
            そして、いかに
            <strong className="has-text-marker">多くのひとの共感や関心</strong>
            を集められるかを競います。
            <br />
            <br />
            <strong className="has-text-marker">女性像やお茶大生像を、出場者にも観覧者にも強制しないコンテスト</strong>
            を目指します。
            <div className="content is-margin-top-3">
              <h5 className="has-text-centered">
                <strong>《水コン2021の新たな点》</strong>
              </h5>
              <div className="is-flex is-align-items-center is-flex-direction-column">
                <ul className="is-flex is-flex-direction-column">
                  <li>グランプリ像を画一化しない水コンのコンセプト</li>
                  <li>ファイナリスト全員に対する表彰</li>
                  <li>結果発表時の服装はファイナリストが決める</li>
                  <li>ハンドルネームの使用を許可する</li>
                  <p className="has-text-right">etc.</p>
                </ul>
              </div>
            </div>
          </p>
          <div className="theme">
            <figure className="image">
              <img src="/img/theme.png" width={260} />
            </figure>
            <p className="has-text-centered has-text-weight-bold has-text-dark is-margin-top-1-5 is-size-7-mobile">
              水コン2021は「開花宣言」というテーマを掲げます。
              <br />
              <br />
              「開花」という言葉には、"草木の花が咲くこと"に加えて
              <br />
              "物事が盛んになり、成果があらわれること"
              <br />
              という意味が含まれます。
              <br />
              <br />
              従来のミスコンが囚われていた固定観念に向き合い、
              <br />
              解き放たれた新たな形へと花開きます。
            </p>
          </div>
        </div>
      </section>
      {/* FINALIST */}
      <section id="finalist" className="section">
        <div className="container">
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Finalist</h2>
          </div>
          <div className="columns is-multiline is-mobile is-margin-bottom-3">
            <Entries state={entries} noshuffle>
              {(list: Entry[]) =>
                  list.map((item: Entry) => (
                      <div
                          className={classNames('column is-4-tablet is-6-mobile')}
                          key={item.entry_id}
                      >
                        <EntryCard onlive={false} entry={item} href={`/profiles/${item.entry_id}`} />
                      </div>
                  ))
              }
            </Entries>
          </div>
        </div>
      </section>
      {/* CONTENTS */}
      <section id="contents" className="section">
        <div className="container" style={{ maxWidth: 520 }}>
          <div className="is-margin-bottom-1-5">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Contents</h2>
          </div>
          <p
            className="notification has-text-centered has-background-white is-size-7-mobile is-margin-bottom-1-5"
            style={{ margin: '0 auto' }}
          >
            水コン2021では、7〜11月にかけて、5つの企画を実施します。
          </p>
          <div className="columns is-centered is-multiline">
            <div className="column is-12">
              <StepBox
                className="box"
                style={{ maxWidth: '100%' }}
                src="/img/step1.png"
                alt={'Twitter自己紹介企画'}
                boxTitle={
                  <>
                    <span className="tag has-text-futura-pt has-text-italic is-size-4">1</span>
                    <br />
                    <br />
                    <strong className="has-text-marker">「Twitter自己紹介企画」</strong>
                  </>
                }
              >
                <p className="is-size-7-mobile">
                  140字・写真4枚のみで自己紹介を兼ねた自己PRをしてもらいます。
                  <br />
                  定められた期間中の「いいね」数を競います。
                </p>
                <div className="has-text-centered is-margin-top-1-5 is-margin-bottom-1">
                  <button className="button is-rounded" onClick={() => setOpenContent1(true)}>企画レポート</button>
                </div>
              </StepBox>
            </div>
            <div className="column is-12">
              <StepBox
                className="box"
                style={{ maxWidth: '100%' }}
                src="/img/step2.png"
                alt={'お茶大紹介企画'}
                boxTitle={
                  <>
                    <span className="tag has-text-futura-pt has-text-italic is-size-4">2</span>
                    <br />
                    <br />
                    <strong className="has-text-marker">「お茶大紹介企画」</strong>
                  </>
                }
              >
                <p className="is-size-7-mobile">
                  学内サークル、お茶大の好きな場所、おすすめの学食などファイナリストにお茶大を紹介してもらいます。
                  <br />
                  ファイナリストのお茶大生としての姿を発信するとともに、お茶大生にとって水コンをもっと身近に感じてもらうための企画です。
                </p>
                <div className="has-text-centered is-margin-top-1-5 is-margin-bottom-1">
                  <button className="button is-rounded" onClick={() => setOpenContent2(true)}>企画レポート</button>
                </div>
              </StepBox>
            </div>
            <div className="column is-12">
              <StepBox
                className="box"
                style={{ maxWidth: '100%' }}
                src="/img/step3.png"
                alt={'ビブリオバトル'}
                boxTitle={
                  <>
                    <span className="tag has-text-futura-pt has-text-italic is-size-4">3</span>
                    <br />
                    <br />
                    <strong className="has-text-marker">「ビブリオバトル」</strong>
                  </>
                }
              >
                <p className="is-size-7-mobile">
                  学内向けビブリオバトルは「本を通してその人を知る」ことを目的とした企画です。
                  <br />
                  自分が面白いと思った本を持ち寄って5分間で発表し、「どの本が1番読みたくなったか？」を基準とした投票を行います。
                </p>
                <div className="has-text-centered is-margin-top-1-5 is-margin-bottom-1">
                  <button className="button is-rounded" onClick={() => setOpenContent3(true)}>企画レポート</button>
                </div>
              </StepBox>
            </div>
            <div className="column is-12">
              <StepBox
                className="box"
                style={{ maxWidth: '100%' }}
                src="/img/step2.png"
                alt={'名前をふせたレポート企画'}
                boxTitle={
                  <>
                    <span className="tag has-text-futura-pt has-text-italic is-size-4">4</span>
                    <br />
                    <br />
                    <strong className="has-text-marker">「名前をふせたレポート企画」</strong>
                  </>
                }
              >
                <p className="is-size-7-mobile">
                  なんらかの社会問題に対して意見を発信してもらいます。
                  <br />
                  顔の見えないものを評価軸に入れることで「容姿のみのコンテスト」という一面を排除します。
                </p>
                <div className="has-text-centered is-margin-top-1-5 is-margin-bottom-1">
                  <button className="button is-rounded" onClick={() => setOpenContent4(true)}>企画レポート</button>
                </div>
              </StepBox>
            </div>
            <div className="column is-12">
              <StepBox
                className="box"
                style={{ maxWidth: '100%' }}
                src="/img/step2.png"
                alt={'自己PR（ステージ発表）'}
                boxTitle={
                  <>
                    <span className="tag has-text-futura-pt has-text-italic is-size-4">5</span>
                    <br />
                    <br />
                    <strong className="has-text-marker">「自己PR（ステージ発表）」</strong>
                  </>
                }
              >
                <p className="is-size-7-mobile">徽音祭当日、ステージ上で自分の魅力を最大限表現してもらいます。</p>
              </StepBox>
            </div>
          </div>
        </div>
      </section>
      {/* EXHIBITION */}
      <section className="section">
        <div id="vote" className="container" style={{ maxWidth: 520 }}>
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">The Details of Voting</h2>
          </div>
          <figure className="image is-margin-bottom-4 is-margin-top-2" style={{ maxWidth: 480, margin: '0 auto' }}>
            <img src="/img/vote_description.jpg" />
          </figure>
          <div>
            <p className="is-size-7-mobile">
              昨年の水コンの反省点の一つとして、投票期間が長いということが挙げられます。また、大学内外の票を分けてきたことをわかりやすく外部に発信してこなかったため、その事実があまり認識されておりませんでした。
              <br />
              <br />
              そのため、お茶の水女子大学で行われるコンテストという役割があまり果たせていなかったのではないかという意見も挙げられました。
              <br />
              <br />
              それらを踏まえて、
              <strong className="has-text-marker">❶お茶大生票 ❷当日票 ❸web投票 ❹企画投票 の4種類の投票</strong>
              を行うことを決定いたしました。全ての投票が終了した時に、最終的な票数の割合の調整を行って、お茶大生票の比率を大きくします。（結果発表時にはその計算方法も発表いたします）
              <br />
              <br />
              お披露目から徽音祭までの4ヶ月間の活動で、ファイナリストには自分の更なる魅力を見つけ、それを発信してもらいます。
              <br />
              <br />
              そして、いかに多くのひとの共感や関心を集められるかを競います。
              <br />
              <br />
              女性像やお茶大生像を、出場者にも観覧者にも強制しないコンテストを目指します。
            </p>
          </div>
        </div>
      </section>
      {/* RESULTS */}
      <section id="results" className="section">
        <div className="container">
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Results</h2>
          </div>
          <p
              className="notification is-padding-top-1-5 is-padding-bottom-1-5 is-padding-left-1-5 is-padding-right-1-5 is-margin-bottom-3 is-size-7-mobile"
              style={{ margin: '0 auto', maxWidth: 520 }}
          >
            水コン2021は、11月14日に結果発表を行い、ファイナリスト全員に対する表彰を行いました。これは水コン2021の新しい取り組みの1つです。<br />
            徽音祭の開催にあたり、ご協力いただきました企業のみなさま、大学関係者のみなさまに心より御礼申し上げます。みなさまのおかげで、第72回徽音祭及び水コン2021はフィナーレを迎えることができました。今後とも徽音祭実行委員会及び水コンをどうぞよろしくお願いいたします。
          </p>
          <div className="columns is-multiline is-mobile is-margin-bottom-3">
            <div
                className={classNames('column is-4-tablet is-6-mobile')}>
              <div className="entry-card">
                <Link href={`/profiles/1`}>
                  <Photo
                      src={`/img/entry/1/result.jpg`}
                      alt={""}
                  >
                    <figcaption className="entry-card-mask">
                      <figure className="image is-1by1">
                        <figcaption>
                          <p className="has-text-weight-bold">詳しく見る</p>
                        </figcaption>
                      </figure>
                    </figcaption>
                    <div className="entry-card-prize">
                      <p className="is-size-7-mobile is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag">
                        新進気鋭賞
                      </p>
                    </div>
                  </Photo>
                  <div className="entry-card-title">
                    <p className="entry-card-title-name is-size-6-mobile is-margin-bottom-5px">{"関野 佳穏"}</p>
                    <p className="entry-card-title-university is-size-7-mobile">{"文教育学部 \n言語文化学科"} {"1年"}</p>
                  </div>
                  <div className="is-size-7-mobile is-size-6">
                    新進気鋭とは、新たにその分野に現れ、意気込みが鋭く、将来有望なさま、また、そのような人のことを指す四字熟語です。 <br />
                    水コン 2021 で唯一の 1 年生であった関野さん。 その心意気は非常に鋭く、SNS 上での意見の発信や、ビブリオバトルをはじめとした企画ではその存在感を示しました。
                  </div>
                </Link>
              </div>
            </div>
            <div
                className={classNames('column is-4-tablet is-6-mobile')}>
              <div className="entry-card">
                <Link href={`/profiles/2`}>
                  <Photo
                      src={`/img/entry/2/result.jpg`}
                      alt={""}
                  >
                    <figcaption className="entry-card-mask">
                      <figure className="image is-1by1">
                        <figcaption>
                          <p className="has-text-weight-bold">詳しく見る</p>
                        </figcaption>
                      </figure>
                    </figcaption>
                    <div className="entry-card-prize">
                      <p className="is-size-7-mobile is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag">
                        勇往邁進賞
                      </p>
                    </div>
                  </Photo>
                  <div className="entry-card-title">
                    <p className="entry-card-title-name is-size-6-mobile is-margin-bottom-5px">{"堀田 明日香"}</p>
                    <p className="entry-card-title-university is-size-7-mobile">{"生活科学部 \n人間生活学科"} {"3年"}</p>
                  </div>
                  <div className="is-size-7-mobile is-size-6">
                    勇往邁進とは、元気よく前進することを恐れることなく、自分の目的や目標に向かってひたすら前進することを意味する四字熟語です。<br />
                    自分の目標ややりたいことに対して真っ直ぐな姿勢で活動していた堀田さん。「自分」や「自分らしさ」というものを大切にしながら発信している姿が印象的でした。
                  </div>
                </Link>
              </div>
            </div>
            <div
                className={classNames('column is-4-tablet is-6-mobile')}>
              <div className="entry-card">
                <Link href={`/profiles/3`}>
                  <Photo
                      src={`/img/entry/3/result.jpg`}
                      alt={""}
                  >
                    <figcaption className="entry-card-mask">
                      <figure className="image is-1by1">
                        <figcaption>
                          <p className="has-text-weight-bold">詳しく見る</p>
                        </figcaption>
                      </figure>
                    </figcaption>
                    <div className="entry-card-prize">
                      <p className="is-margin-bottom-0-5 is-warning is-size-7-mobile is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag">
                        グランプリ
                      </p><br />
                      <p className="is-size-7-mobile is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag">
                        活気横溢賞
                      </p>
                    </div>
                  </Photo>
                  <div className="entry-card-title">
                    <p className="entry-card-title-name is-size-6-mobile is-margin-bottom-5px">{"原 亜由子"}</p>
                    <p className="entry-card-title-university is-size-7-mobile">{"文教育学部 \n芸術表現行動学科"} {"3年"}</p>
                  </div>
                  <div className="is-size-7-mobile is-size-6">
                    活気横溢とは、いきいきとした気分が溢れんばかりにみなぎっていることを意味する四字熟語です。<br />
                    学生数が少ないお茶大の中でも、特に人数が少ない舞踊学科からの挑戦だった原さん。得意で専攻であるダンス表現からはもちろん、SNSで行われた写真や文章の表現全てからもいきいきとした明るさが感じられました。
                  </div>
                </Link>
              </div>
            </div>
            <div
              className={classNames('column is-4-tablet is-6-mobile')}>
            <div className="entry-card">
              <Link href={`/profiles/4`}>
                <Photo
                    src={`/img/entry/4/result.jpg`}
                    alt={""}
                >
                  <figcaption className="entry-card-mask">
                    <figure className="image is-1by1">
                      <figcaption>
                        <p className="has-text-weight-bold">詳しく見る</p>
                      </figcaption>
                    </figure>
                  </figcaption>
                  <div className="entry-card-prize">
                    <p className="is-margin-bottom-0-5 is-warning is-size-7-mobile is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag">
                      準グランプリ
                    </p><br />
                    <p className="is-size-7-mobile is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag">
                      積土成山賞
                    </p>
                  </div>
                </Photo>
                <div className="entry-card-title">
                  <p className="entry-card-title-name is-size-6-mobile is-margin-bottom-5px">{"山内 一姫"}</p>
                  <p className="entry-card-title-university is-size-7-mobile">{"生活科学部 \n食物栄養学科"} {"4年"}</p>
                </div>
                <div className="is-size-7-mobile is-size-6">
                  積土成山とは、努力を積み重ねていけば、やがて大成することを意味する四字熟語です。<br />
                  山内さんは、「自分磨き投稿」やダンス全ジャンルに挑戦する「自己 PR チャレンジ」が印象的でした。常に努力を重ね、水コンで最初に掲げた山内さん自身の「努力したい」という目標を達成しようとする活動がとても印象的でした。
                </div>
              </Link>
            </div>
          </div>
            <div
                className={classNames('column is-4-tablet is-6-mobile')}>
              <div className="entry-card">
                <Link href={`/profiles/5`}>
                  <Photo
                      src={`/img/entry/5/result.jpg`}
                      alt={""}
                  >
                    <figcaption className="entry-card-mask">
                      <figure className="image is-1by1">
                        <figcaption>
                          <p className="has-text-weight-bold">詳しく見る</p>
                        </figcaption>
                      </figure>
                    </figcaption>
                    <div className="entry-card-prize">
                      <p className="is-size-7-mobile is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag">
                        英明果敢賞
                      </p>
                    </div>
                  </Photo>
                  <div className="entry-card-title">
                    <p className="entry-card-title-name is-size-6-mobile is-margin-bottom-5px">{"神野 莉子"}</p>
                    <p className="entry-card-title-university is-size-7-mobile">{"理学部 \n情報科学科"} {"4年"}</p>
                  </div>
                  <div className="is-size-7-mobile is-size-6">
                    英明果敢とは、優れて賢い『英明』と、決断力に富み、物事を思い切ってする様子を意味する『果敢』を合わせた、才知に優れ、道理に明るく、思い切りのいいことを表す四字熟語です。<br />
                    多くの知識が問われるクイズというコンテンツをSNS上の独自企画やスペース機能を用いて行った神野さん。才知に優れた様々な思い切った活動が印象的でした。
                  </div>
                </Link>
              </div>
            </div>
            <div
                className={classNames('column is-4-tablet is-6-mobile')}>
              <div className="entry-card">
                <Link href={`/profiles/6`}>
                  <Photo
                      src={`/img/entry/6/result.jpg`}
                      alt={""}
                  >
                    <figcaption className="entry-card-mask">
                      <figure className="image is-1by1">
                        <figcaption>
                          <p className="has-text-weight-bold">詳しく見る</p>
                        </figcaption>
                      </figure>
                    </figcaption>
                    <div className="entry-card-prize">
                      <p className="is-size-7-mobile is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag">
                        和風慶雲賞
                      </p>
                    </div>
                  </Photo>
                  <div className="entry-card-title">
                    <p className="entry-card-title-name is-size-6-mobile is-margin-bottom-5px">{"漉野 ヨリ"}</p>
                    <p className="entry-card-title-university is-size-7-mobile">{"文教育学部 \n言語文化学科"} {"4年"}</p>
                  </div>
                  <div className="is-size-7-mobile is-size-6">
                    和風慶雲とは、穏やかに吹くそよ風と、吉兆を示すめでたい雲であり、温厚で徳を備えた人格者を形容する四字熟語です。「#ヨリより」を用いて投稿された 「自分を愛すること」に対するご自身の考えを綴った文章は、水コン2021を見ていた自分に自信を持てずにいた人たちを励ますものだったでしょう。
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* OUTLINE */}
      <section className="section">
        <div className="container" style={{ maxWidth: 480 }}>
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Outline</h2>
          </div>
          <div className="columns is-mobile is-multiline">
            <div className="column is-5">
              <p className="title is-6">主催団体名</p>
            </div>
            <div className="column is-7">
              <p className="subtitle is-6">お茶の水女子大学徽音祭実行委員会</p>
            </div>
            <div className="column is-5">
              <p className="title is-6">開催形態</p>
            </div>
            <div className="column is-7">
              <p className="subtitle is-6">
              全面オンライン
              </p>
            </div>
            <div className="column is-5">
              <p className="title is-6">Twitter</p>
            </div>
            <div className="column is-7">
              <p className="subtitle is-6 has-text-futura-pt has-text-italic">
                <span className="has-text-marker">
                  <a href="https://twitter.com/mizucon_2021">@mizucon_2021</a>
                </span>
              </p>
            </div>
            <div className="column is-5">
              <p className="title is-6">Instagram</p>
            </div>
            <div className="column is-7">
              <p className="subtitle is-6 has-text-futura-pt has-text-italic">
                <span className="has-text-marker">
                  <a href="https://www.instagram.com/mizucon_2021">@mizucon_2021</a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container" style={{ maxWidth: 480 }}>
          <div className="is-margin-bottom-1-5">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Twitter</h2>
          </div>
          <Twitter user="mizucon_2021" />
        </div>
      </section>
      <Footer />
      <div className={classNames("modal", {
        "is-active": openContent1
      })}>
        <div className="modal-background has-background-white"></div>
        <div className="modal-content" style={{width: "100%", maxHeight: "100vh"}}>
          <section className="section is-padding-bottom-0">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">About</h2>
              </div>
              <p>
                自己紹介企画とは、<strong className="has-text-marker">ファイナリストが4枚の画像と140字で自分の魅力を伝える企画</strong>です。
                お披露目の7月12日から7月22日までの10日間に該当するツイートに寄せられた、いいね数を競いました。
              </p>
            </div>
          </section>
          <section className="section">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Contents</h2>
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.1</p>
                <p>関野 佳穏</p>
                <TweetEmbed id="1414426004286672897" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.2</p>
                <p>堀田 明日香</p>
                <TweetEmbed id="1414429709836775426" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.3</p>
                <p>原 亜由子</p>
                <TweetEmbed id="1414433126684651522" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.4</p>
                <p>山内 一姫</p>
                <TweetEmbed id="1414426645163696131" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.5</p>
                <p>神野 莉子</p>
                <TweetEmbed id="1414425528644243458" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.6</p>
                <p>漉野 ヨリ</p>
                <TweetEmbed id="1414426446550867968" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
            </div>
          </section>
          <section className="section is-padding-top-0">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Result</h2>
              </div>
              <p className="notification">
                １位 entry no.5 神野 莉子 525票<br />
                ２位 entry no.6 漉野 ヨリ 391票<br />
                ３位 entry no.3 原 亜由子 360票
              </p>
              <div className="has-text-centered is-margin-bottom-1-5">
                <TweetEmbed id="1418397439069884418" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
            </div>
          </section>
          <section className="section is-padding-top-0" />
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={() => setOpenContent1(false)} />
      </div>
      <div className={classNames("modal", {
        "is-active": openContent2
      })}>
        <div className="modal-background has-background-white"></div>
        <div className="modal-content" style={{width: "100%", maxHeight: "100vh"}}>
          <section className="section is-padding-bottom-0">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">About</h2>
              </div>
              <p>
                お茶大紹介企画とは、運営が提示するテーマに沿って、ファイナリストが文章や写真、動画などをSNSに投稿し、<strong className="has-text-marker">お茶大の魅力を発信する企画です。</strong>
                ファイナリストの8月16日から8月21日までのSNS投稿に対するweb投票数を競いました。
              </p>
            </div>
          </section>
          <section className="section">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Contents</h2>
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.1</p>
                <p>関野 佳穏</p>
                <TweetEmbed id="1427112680930770949" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
                <TweetEmbed id="1428123011085787137" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.2</p>
                <p>堀田 明日香</p>
                <TweetEmbed id="1427110601990426624" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
                <TweetEmbed id="1428219619437735938" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.3</p>
                <p>原 亜由子</p>
                <TweetEmbed id="1427584204372467722" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
                <TweetEmbed id="1428678868286664708" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.4</p>
                <p>山内 一姫</p>
                <TweetEmbed id="1427456383553740826" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
                <TweetEmbed id="1428559410662580229" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.5</p>
                <p>神野 莉子</p>
                <TweetEmbed id="1427827877093904387" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
                <TweetEmbed id="1428996617932181504" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.6</p>
                <p>漉野 ヨリ</p>
                <TweetEmbed id="1427833901871861766" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
                <TweetEmbed id="1428921951561469965" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
            </div>
          </section>
          <section className="section is-padding-top-0">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Result</h2>
              </div>
              <p className="notification">
                １位 entry no.3　原亜由子<br />
                ２位 entry no.5　神野莉子<br />
                ３位 entry no.2　堀田明日香
              </p>
              <div className="has-text-centered is-margin-bottom-1-5">
                <TweetEmbed id="1433788203270488077" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
            </div>
          </section>
          <section className="section is-padding-top-0" />
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={() => setOpenContent2(false)} />
      </div>
      <div className={classNames("modal", {
        "is-active": openContent3
      })}>
        <div className="modal-background has-background-white"></div>
        <div className="modal-content" style={{width: "100%", maxHeight: "100vh"}}>
          <section className="section is-padding-bottom-0">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">About</h2>
              </div>
              <p>
                水コン式ビブリオバトルとは、<strong className="has-text-marker">ファイナリスト6名が面白いと思った本を各自5分で紹介する企画</strong>です。発表後、「どの本が一番読みたくなったか」という基準で投票してもらい、その投票数を競いました。
              </p>
            </div>
          </section>
          <section className="section">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Contents</h2>
              </div>
              <figure className="image is-16by9">
                <iframe className="has-ratio" width="640" height="360" src="https://www.youtube.com/embed/6HhLWvlI0EU"
                        frameBorder="0" allowFullScreen
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"

                />
              </figure>
            </div>
          </section>
          <section className="section is-padding-top-0">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Result</h2>
              </div>
              <p className="notification">
                １位 entry no.3　原亜由子<br />
                ２位 entry no.1　関野佳穏<br />
                ３位 entry no.5　神野莉子
              </p>
              <div className="has-text-centered is-margin-bottom-1-5">
                <TweetEmbed id="1444119874507137028" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
            </div>
          </section>
          <section className="section is-padding-top-0" />
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={() => setOpenContent3(false)} />
      </div>
      <div className={classNames("modal", {
        "is-active": openContent4
      })}>
        <div className="modal-background has-background-white"></div>
        <div className="modal-content" style={{width: "100%", maxHeight: "100vh"}}>
          <section className="section is-padding-bottom-0">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">About</h2>
              </div>
              <p>
                レポート企画は、<strong className="has-text-marker">長期化するコロナ禍における「大学生と〇〇」</strong>というテーマで、ファイナリスト６名各自が〇〇の内容を自由に考え作成した2000字程度のレポートを匿名で公開する企画です。発表後、「新しい考え方や視点を得られた」「興味深い内容であると思った」「レポート内の構成や表現が優れていると感じた」という３つの基準で投票してもらい、その投票数を競いました。
              </p>
            </div>
          </section>
          <section className="section">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Contents</h2>
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.1</p>
                <p>関野 佳穏</p>
                <TweetEmbed id="1451702324980707328" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.2</p>
                <p>堀田 明日香</p>
                <TweetEmbed id="1451702162308825093" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.3</p>
                <p>原 亜由子</p>
                <TweetEmbed id="1451702196400054272" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.4</p>
                <p>山内 一姫</p>
                <TweetEmbed id="1451702260820430854" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.5</p>
                <p>神野 莉子</p>
                <TweetEmbed id="1451702298107805702" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
              <div className="has-text-centered is-margin-bottom-1-5">
                <p className="is-size-6 has-text-futura-pt has-text-italic">ENTRY NO.6</p>
                <p>漉野 ヨリ</p>
                <TweetEmbed id="1451702225592483841" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
            </div>
          </section>
          <section className="section is-padding-top-0">
            <div className="container" style={{ maxWidth: 550 }}>
              <div className="is-margin-bottom-3">
                <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered">Result</h2>
              </div>
              <p className="notification">
                １位 entry no.2 堀田明日香<br />
                ２位 entry no.3 原亜由子<br />
                ３位 entry no.1 関野佳穏
              </p>
              <div className="has-text-centered is-margin-bottom-1-5">
                <TweetEmbed id="1454661585390030850" options={{lang: "ja"}} className="is-flex is-justify-content-center" />
              </div>
            </div>
          </section>
          <section className="section is-padding-top-0" />
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={() => setOpenContent4(false)} />
      </div>
    </main>
  );
}
