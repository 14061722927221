import React, { useState, useEffect } from 'react';
import shuffle from 'lodash/shuffle';
import { Entry, EntryBlock, EntryStage } from '../../types';
import { ResourceResult } from 'retil';

interface EntriesProps {
  state: ResourceResult<Entry[], any, any>;
  children: (list: Entry[]) => any;
  block?: EntryBlock;
  stage?: EntryStage;
  except?: Entry;
  size?: number;
  noshuffle?: boolean;
}

export function Entries(props: EntriesProps) {
  const { size, state, children, except, stage, block } = props;
  const [result, setResult] = useState<Entry[]>([]);
  useEffect(() => {
    if (!state.pending) {
      let result: Entry[] = props.noshuffle ? state.data : shuffle(state.data);
      if (except) {
        result = result.filter(entry => entry.entry_id !== except.entry_id);
      }
      if (stage) {
        result = result.filter(entry => entry.stage >= stage);
      }
      if (block) {
        result = result.filter(entry => entry.block === block);
      }
      if (size) {
        result = result.slice(0, size <= state.data.length ? size : state.data.length + 1);
      }
      setResult(result);
    }
  }, [state.pending, except, stage, block]);
  if (state.pending) {
    return (
      <div className="column">
        <p className="has-text-centered">
          <a className="button is-loading is-text is-large" style={{ height: 240 }} />
        </p>
      </div>
    );
  }
  return <>{children(result)}</>;
}
