import React from 'react';
const logo = require('./logo.svg');

export function TiktokButton(props: React.AnchorHTMLAttributes<HTMLAnchorElement>) {
    return (
        <a {...props} className="button is-tiktok is-white">
            <span className="icon is-small">
                <img src={logo} />
            </span>
        </a>
    );
}
