import { createDocumentResource, createQueryResource, list } from 'retil';
import { Entry } from '@2021-contest-web/components/src/types';

export const entry = createDocumentResource<Entry>('entry');
export const entryList = createQueryResource('entryList', {
    for: list(entry),
    load: async () => {
        return [
            {
                id: 1,
                entry_id: 1,
                block: 'A',
                stage: '1',
                name: '関野 佳穏',
                "faculty": "文教育学部 \n言語文化学科",
                "course": "",
                "grade": "1年",
                "hometown": "神奈川県",
                "motto": "Keep on keeping on：直訳は「続けることを続けよ」です。",
                twitter: "mizucon21_01",
                instagram: "mizu_con2101",
                interview1: "今まで足踏みしていたところから、夢に向かって1歩を踏み出したいと思ったからです。",
                interview2: "それぞれ多様な個性を持っていて、それを尊重し合えるところ。",
                interview3: "重松清さん『きみの友だち』\n「友だち」の本当の意味、そのヒントをくれます。",
                interview4: "何事にも諦めないで挑戦するところ!",
                interview5: "自分や世界を表現することで、これまで自分が考えてきたことを伝えたい。そして、何かに挑戦している人、挑戦したい夢がある人に勇気や感動を届けたいです!",
                interview6: "私はまだ1年生ですが、受験で得た諦めない強さを以て、この4ヶ月間を全力で走り抜きます。見てくださる方の背中を押せるような、そんな活動をしたいです。"
            },
            {
                id: 2,
                entry_id: 2,
                block: 'A',
                stage: '1',
                name: '堀田 明日香',
                "faculty": "生活科学部 \n人間生活学科",
                "course": "生活社会科学講座",
                "grade": "3年",
                "hometown": "東京生まれ（〜5歳）大阪育ち（〜10歳）宮崎育ち（〜18歳）",
                "motto": "ハングリー精神",
                twitter: "mizucon21_02",
                instagram: "mizucon21_2",
                interview1: "『開花宣言』にビビッときたから!!",
                interview2: "都心へのアクセス、、(違うか)\n真面目に答えると、個性が認められる場所であるところ!",
                interview3: "『スラムダンク勝利学』\nみんなわかってるけど、実践出来る人はごく僅かな勝利法をスラムダンクからわかりやすく落とし込める。とにかく体育会系っていいなって思える一冊。",
                interview4: "自分の芯を曲げない強さがあるところ。",
                interview5: "世間体とか固定概念は存在しないということ。ありのままの自分で生きることの意義。ジェンダーという分野について。",
                interview6: "とにかく自分にできることは一生懸命やる。いい意味でミスコンという枠を超えた活動をしたい。"
            },
            {
                id: 3,
                entry_id: 3,
                block: 'A',
                stage: '1',
                name: '原 亜由子',
                "faculty": "文教育学部 \n芸術表現行動学科",
                "course": "舞踊教育学コース",
                "grade": "3年",
                "hometown": "岐阜県",
                "motto": "色気より元気",
                twitter: "mizucon21_03",
                instagram: "mizucon21_3",
                interview1: "水コンの改革に参加することで自分自身の中のミスコンや美に対する考え方を変えたいと思ったから。友人が応援してくれたから!",
                interview2: "少人数で専門的な学びができるところ!努力する学生がたくさんいるところ‼︎",
                interview3: "コンドルズ血風録!大好きなダンスグループのノンフィクションで、本なのに汗臭いくらいの熱量あり、明日からも楽しく踊ろう(生きよう)〜‼︎と思わせてくれる本です。",
                interview4: "何事にも取り掛かりが早いところと笑顔!",
                interview5: "お茶大の魅力と心も身体も元気になるようなパワーをお届けします!",
                interview6: "ワクワクを勇気に変えて新しい自分に出会いたい。楽しみます‼︎周りの人も楽しませれたらいいな‼︎"
            },
            {
                id: 4,
                entry_id: 4,
                block: 'A',
                stage: '1',
                name: '山内 一姫',
                "faculty": "生活科学部 \n食物栄養学科",
                "course": "",
                "grade": "4年",
                "hometown": "京都府",
                "motto": "意思あるところに道は開ける",
                twitter: "mizucon21__04",
                instagram: "mizucon21_4",
                interview1: "ミスコンに出ていた友人に刺激を受けた、学生生活最後に何かに挑戦したくなった、人生で一番大きな勇気を出すような経験をしたかったetc.色々な気持ちがあり出場を決意しました。",
                interview2: "全部です。人も環境も、自分にとって最高の学校です。︎",
                interview3: "博士の愛した数式。1番感動したからです。",
                interview4: "努力家、成長意欲",
                interview5: "お茶大の魅力、自分を大切にする心。受験生への情報提供や応援もしたいです!",
                interview6: "1人でも多くの人に、何かプラスの影響を与えられるような活動にしたいと思っています。約4ヶ月間頑張ります!よろしくお願いします。"
            },
            {
                id: 5,
                entry_id: 5,
                block: 'A',
                stage: '1',
                name: '神野 莉子',
                "faculty": "理学部 \n情報科学科",
                "course": "",
                "grade": "4年",
                "hometown": "愛知県(生まれてから高校まで)",
                "motto": "蒔かぬ種は生えぬ",
                twitter: "mizucon21_05",
                instagram: "mizucon21_5",
                interview1: "今やっているクイズの活動をもっといろんな人に知って欲しい!と思ったのと、「開花宣言」という今年の水コンのコンセプトに心惹かれ応募しました。水コンに応募する事自体初めてなので、ファイナリストに選んでいただけてとてもびっくりしています。",
                interview2: "大学全体が少人数で、先生方に丁寧にご指導いただけるところ。",
                interview3: "『岳物語』(著・椎名誠)\n読み終わる頃には心が温まっている大好きな本です。",
                interview4: "「やりたい!」と思ったことにどんどんチャレンジするところ",
                interview5: "水コンを通してお茶大、そしてクイズの魅力をたくさん発信していきます! #じんじんこつこつクイズ で1日1問、#じんじんうぃ~クイ~ で週に1度視聴者参加型のミニクイズ企画を開催しますので、是非気軽に遊びに来てください。お待ちしています(詳細はTwitterにて)",
                interview6: "4ヶ月間全力で走り抜けて行くので、みなさんも付いて来て下さい!"
            },
            {
                id: 6,
                entry_id: 6,
                block: 'A',
                stage: '1',
                name: '漉野 ヨリ',
                "faculty": "文教育学部 \n言語文化学科",
                "course": "中国語圏言語文化コース",
                "grade": "4年",
                "hometown": "埼玉県",
                "motto": "出来るか出来ないかじゃなくて、やる！",
                twitter: "mizucon21_06",
                instagram: "mizu_con2106",
                interview1: "最後の最後に後悔したくない!とりあえずエントリーしよう!と思ったのが大きなきっかけです。",
                interview2: "小規模なので、とにかく皆の距離感が近い!",
                interview3: "「星の王子さま」小学生のときに挫折して高校生のテスト前に再挑戦したところ、めちゃくちゃ感動したからです。笑",
                interview4: "よく笑う、いろいろ手を出すところ!",
                interview5: "あなたも私も、誰もが可愛くてかっこよくて美しくて最強だということ",
                interview6: "たくさん考え悩みながらも精一杯活動していきます。よろしくお願いします!"
            },
        ];
    },
});
