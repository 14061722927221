import React from 'react';
import { mount, compose } from 'navi';
import TopApp from './top';
import ProfileApp from './profiles';

const apps = compose(
  mount({
    '/': TopApp,
    '/profiles': ProfileApp,
  }),
);

export default apps;
