import React from 'react';

export function Footer() {
  return (
    <footer className="footer" style={{ paddingBottom: '3rem' }}>
      <div className="content has-text-centered">
        <p className="is-size-7 has-text-grey">コンテストに関するお問い合わせはこちら</p>
        <a className="button is-size-7 is-light is-margin-bottom-2" href="mailto:kifc2021.mizucon@gmail.com">
          <span className="icon">
            <i className="far fa-envelope" />
          </span>
          <span>お問い合わせ</span>
        </a>
        <p className="is-size-7 is-text-futura has-text-grey">
          Copyright © Mizucon 2021 All Rights Reserved.
        </p>
      </div>
    </footer>
  );
}
